<template>
  <div class="mandanten">
    <v-row>
      <v-col cols="12" sm="12" offset-sm="0">
        <v-card id="mandantenlistcard" :loading="loading">
          <h2 v-if="!!!mandantenList">Keine Mandanten gefunden</h2>

          <v-expansion-panels
            :hover="true"
            :focusable="true"
            :multiple="true"
            value="0"
            v-model="expansionPanels"
          >
            <v-expansion-panel
              v-for="(item, index) in mandantenList"
              :key="index"
            >
              <v-expansion-panel-header>
                {{ item.firmenname }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <MandantenListItem :mandant="item" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dlgDelete" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Mandanten löschen?</v-card-title>
        <v-card-text>
          Soll der Mandant
          <strong>{{ deleteMandantenName }}</strong> wirklich gelöscht werden?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" outlined text @click="dlgDelete = false"
            >Abbrechen</v-btn
          >
          <v-btn dark color="red darken-1" @click="deleteMandant">
            <v-icon>mdi-delete</v-icon>Löschen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MandantenService from "@/services/MandantenService.js";
import MandantenListItem from "@/components/clients/MandantenListItem";

export default {
  name: "MandantenList",
  data: () => ({
    loading: false,
    dlgDelete: false,
    deleteItem: false,
    deleteMandantenName: "",
    expansionPanels: []
  }),
  components: { MandantenListItem },
  methods: {
    askDeleteMandant(index) {
      this.deleteMandantenName = this.getNameByIndex(index);
      this.dlgDelete = true;
      this.deleteItem = index;
    },
    deleteMandant() {
      this.dlgDelete = false;
      MandantenService.delete(this.mandantenList[this.deleteItem]._id).then(
        () => {
          this.mandantenList.splice(this.deleteItem, 1);
          this.$notify({
            group: "dataline",
            type: "info",
            title: "Mandant gelöscht",
            text: "Mandant " + this.deleteMandantenName + " wurde gelöscht"
          });
          this.deleteMandantenName = "";
        }
      );
    },
    getNameByIndex(index) {
      if (this.mandantenList == null) {
        return "";
      } else
        return (
          this.mandantenList[index].firstname +
          " " +
          this.mandantenList[index].lastname
        );
    }
  },

  computed: {
    mandantenList() {
      return this.$store.getters["mandant/getMandantenList"];
    }
  },

  created() {
    this.loading = true;
    this.$store
      .dispatch("mandant/fetchMandantenList")
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        if (error.response.status != 401) {
          this.$notify({
            group: "dataline",
            type: "error",
            title: "Fehler",
            text: error.message
          });
        }
      });
  }
};
</script>

<style scoped>
#mandantenlistcard {
  margin: 20px;
}
</style>

<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" xs="12">
        <v-container>
          <v-btn
            :to="{
              name: 'mandant-edit',
              params: { id: this.mandant._id }
            }"
            color="primary"
            class="mr-5"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <h2>Firmendetails</h2>

          <v-row>
            <v-col cols="12" xs="12" sm="6">
              <h4>Firmenname:</h4>
              {{ firmenname }}
            </v-col>
            <v-col cols="12" sm="6">
              <v-img :src="logoUrl" max-height="100px" height="100px" contain />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xs="12" sm="6">
              <h4>Vorname:</h4>
              {{ firstname }}
            </v-col>
            <v-col cols="12" xs="12" sm="6">
              <h4>Nachname:</h4>
              {{ lastname }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xs="12">
              <h4>Adresse:</h4>
              {{ adresse }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xs="12" sm="3">
              <h4>Land:</h4>
              {{ land }}
            </v-col>
            <v-col cols="12" xs="12" sm="3">
              <h4>PLZ:</h4>
              {{ plz }}
            </v-col>
            <v-col cols="12" xs="12" sm="6">
              <h4>Ort:</h4>
              {{ ort }}
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" xs="12" sm="6">
              <h4>Email:</h4>
              {{ email }}
            </v-col>
            <v-col cols="12" xs="12" sm="6">
              <h4>Web:</h4>
              {{ web }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xs="12" sm="6">
              <h4>Telefon:</h4>
              {{ telefon }}
            </v-col>
            <v-col cols="12" xs="12" sm="6">
              <h4>Fax:</h4>
              {{ fax }}
            </v-col>
          </v-row>
          <v-divider />
          <h2>Gewerbe- und Steuernummern</h2>
          <v-row>
            <v-col cols="12" sm="6">
              <h4>Handelsgericht:</h4>
              {{ handelsgericht }}
            </v-col>
            <v-col cols="12" sm="6">
              <h4>Firmenbuchnummer:</h4>
              {{ firmenbuchnr }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <h4>Steuernummer:</h4>
              {{ steuernr }}
            </v-col>
            <v-col cols="12" sm="6">
              <h4>UID:</h4>
              {{ uid }}
            </v-col>
          </v-row>

          <v-divider />
          <h2>Bank- und Kontoinformationen</h2>
          <v-row>
            <v-col cols="12" sm="6">
              <h4>Bank:</h4>
              {{ bank }}
            </v-col>
            <v-col cols="12" sm="6">
              <h4>Kontoinhaber:</h4>
              {{ inhaber }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <h4>Kontonr:</h4>
              {{ kontonr }}
            </v-col>
            <v-col cols="12" sm="6">
              <h4>BLZ:</h4>
              {{ blz }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <h4>IBAN:</h4>
              {{ iban }}
            </v-col>
            <v-col cols="12" sm="6">
              <h4>BIC:</h4>
              {{ bic }}
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "MandantenListItem",
  components: {},
  props: ["mandant"],
  data: () => ({
    firmenname: "",
    lastname: "",
    firstname: "",
    adresse: "",
    plz: "",
    ort: "",
    land: "",
    email: "",
    web: "",
    telefon: "",
    fax: "",
    firmenbuchnr: "",
    uid: "",
    steuernr: "",
    handelsgericht: "",
    bank: "",
    inhaber: "",
    blz: "",
    kontonr: "",
    iban: "",
    bic: "",
    logo: "",
    createdBy: null,
    createdTS: null,
    modifiedTS: null,
    modifiedBy: null,
    id: null,
    requiredRule: [(value) => !!value || "Dieses Feld wird benötigt!"],
    emailRules: [
      (v) => !!v || "E-mail wird benötigt!",
      (v) =>
        /.+@.+/.test(v) ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(v) ||
        "E-mail must be valid"
    ],
    formValid: false
  }),

  methods: {},
  computed: {
    logoUrl() {
      const url = process.env.VUE_APP_API_URL + this.mandant.logo;
      return url;
    }
  },
  created() {
    if (this.mandant != null) {
      this.id = this.mandant._id;
      this.firmenname = this.mandant.firmenname;
      this.lastname = this.mandant.lastname;
      this.firstname = this.mandant.firstname;
      this.adresse = this.mandant.adresse;
      this.plz = this.mandant.plz;
      this.ort = this.mandant.ort;
      this.land = this.mandant.land;
      this.email = this.mandant.email;
      this.web = this.mandant.web;
      this.telefon = this.mandant.telefon;
      this.fax = this.mandant.fax;
      this.firmenbuchnr = this.mandant.firmenbuchnr;
      this.uid = this.mandant.uid;
      this.steuernr = this.mandant.steuernr;
      this.handelsgericht = this.mandant.handelsgericht;
      this.bank = this.mandant.bank;
      this.inhaber = this.mandant.inhaber;
      this.blz = this.mandant.blz;
      this.kontonr = this.mandant.kontonr;
      this.iban = this.mandant.iban;
      this.bic = this.mandant.bic;
      this.logo = this.mandant.logo;
      this.createdBy = this.mandant.createdBy;
      this.createdTS = this.mandant.createdTS;
    }
  }
};
</script>

<template>
  <div>
    <v-container>
      <v-col cols="12" sm="12" offset-sm="0">
        <v-card class="maincard">
          <v-container>
            <v-row align="center">
              <v-col cols="12" xs="12" sm="8">
                <v-card-title>Mandantenverwaltung</v-card-title>
              </v-col>
              <v-col cols="12" xs="12" sm="4" style="text-align: right;">
                <v-btn to="/mandant/edit" color="success">
                  <v-icon style="margin-right: 10px;">mdi-domain-plus</v-icon>
                  <span>Neuer Mandant</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>

          <MandantenList />
        </v-card>
      </v-col>
    </v-container>
  </div>
</template>

<script>
import MandantenList from "@/components/clients/MandantenList.vue";

export default {
  name: "MandantenMgmt",
  components: { MandantenList }
};
</script>

<style scoped></style>
